import React from "react"
import { Box, Flex, Text, Link } from "rebass"
import { useThemeUI } from "theme-ui"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { faFacebookF, faLinkedin } from "@fortawesome/free-brands-svg-icons"
import _ from "lodash"
import GameBanner from "../../game-banner"

const Footer = () => {
  const { theme } = useThemeUI()

  const pageLinks = [
    {
      id: 1,
      label: "Manifesto",
      href: "/manifesto",
    },
    {
      id: 2,
      label: "Services",
      href: "/services",
    },
    {
      id: 3,
      label: "Assets",
      href: "/assets",
    },
    {
      id: 4,
      label: "Press kit",
      href: "/press-kit",
    },
    {
      id: 4,
      label: "Team",
      href: "/team",
    },
    {
      id: 5,
      label: "Privacy policy",
      href: "https://blog.spin-up.it/informativa-privacy-spinup/",
    },
    {
      id: 6,
      label: "Contact us",
      href: "/contact-us",
    },
  ]

  return (
    <Flex flexDirection="column">
      <GameBanner />
      <Flex justifyContent={["center", "flex-start"]} ml={[0, 8]}>
        <Link href="/contact-us">
          <Text
            fontSize={4}
            mb={5}
            display="inline-block"
            px={4}
            backgroundColor="background"
            mt="-40px"
            fontWeight="bold"
            color="text"
            sx={{
              position: "relative",
              zIndex: 1,
            }}
          >
            Contact us
          </Text>
        </Link>
      </Flex>

      <Flex flexDirection="column" px={[3, 8]} sx={{ position: "relative" }}>
        <Flex
          justifyContent="center"
          alignItems="center"
          flexDirection={["column", "row"]}
        >
          <Box width={["90%", 1 / 4]}>
            <Flex pl={[3, 4]} flexDirection="column">
              <Text fontSize={0} mb={2} fontWeight={500} p={1} color="text">
                Spinup S.r.l. a Socio Unico
              </Text>
              <Text fontSize={0} mb={2} fontWeight={300} p={1} color="text">
                Società soggetta a Direzione e Coordinamento di Cloud Care TOPCO
                Ltd.
                <br />
                Sede legale Via Medina, 40 - 80133 Napoli
                <br />
                <a href="tel:+39-081-3937990">+39 081 3937990</a>
              </Text>
              <Text fontSize={0} mb={2} fontWeight={300} p={1} color="text">
                P.IVA: IT08542331213, n. REA 966344
                <br />
                Capitale Sociale: 100.000€ i.v.
              </Text>
            </Flex>
          </Box>

          <Box width={["90%", 3 / 4]}>
            <Flex
              flexWrap="wrap"
              justifyContent={["flex-start", "flex-end"]}
              alignItems="center"
              mt={[5, 0]}
              mb={3}
            >
              {_.map(pageLinks, (link, i) => (
                <Link
                  key={i}
                  href={link.href}
                  sx={{
                    textDecoration: "none",
                    fontSize: [0],
                    color: theme.colors.text,
                    fontWeight: 800,
                    ">div:after": {
                      content: "''",
                      width: 0,
                      height: 1,
                      backgroundColor: theme.colors.text,
                      display: "block",
                      transition: "width .3s ease",
                    },
                    ":hover": {
                      color: theme.colors.text,
                      ">div:after": {
                        width: "100%",
                      },
                    },
                  }}
                  mb={2}
                  p={1}
                  mx={3}
                  mt={1}
                >
                  <Text>{link.label}</Text>
                </Link>
              ))}
            </Flex>
          </Box>
        </Flex>

        <Flex
          bg="background"
          mt={3}
          py={6}
          px={[6, 5]}
          sx={{
            borderTop: `1px solid ${theme.colors.lightgray}`,
          }}
        >
          <Flex alignItems="center">
            <Link
              color="text"
              href="https://www.facebook.com/spinupcompany"
              mr={8}
            >
              <FontAwesomeIcon icon={faFacebookF} />
            </Link>

            <Link
              color="text"
              href="https://www.linkedin.com/company/spin-up-italia/"
            >
              <FontAwesomeIcon icon={faLinkedin} />
            </Link>
          </Flex>
        </Flex>
      </Flex>
    </Flex>
  )
}

export default Footer
