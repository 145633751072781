import React, { useState } from "react"
import { Flex, Text, Box, Link } from "rebass"
import { keyframes } from "@emotion/core"
import Ranking from "../../templates/invaders/components/ranking"

const GameBanner = () => {
  const [showRanking, setShowRanking] = useState(false)
  const glitch1 = keyframes`
    0% {
        clip-path: inset(45% 0 39% 0);
    }
    5% {
        clip-path: inset(96% 0 4% 0);
    }
    10% {
        clip-path: inset(5% 0 59% 0);
    }
    15% {
        clip-path: inset(36% 0 62% 0);
    }
    20% {
        clip-path: inset(93% 0 5% 0);
    }
    25% {
        clip-path: inset(27% 0 30% 0);
    }
    30% {
        clip-path: inset(79% 0 11% 0);
    }
    35% {
        clip-path: inset(12% 0 64% 0);
    }
    40% {
        clip-path: inset(23% 0 29% 0);
    }
    45% {
        clip-path: inset(47% 0 15% 0);
    }
    50% {
        clip-path: inset(64% 0 23% 0);
    }
    55% {
        clip-path: inset(9% 0 30% 0);
    }
    60% {
        clip-path: inset(9% 0 69% 0);
    }
    65% {
        clip-path: inset(4% 0 89% 0);
    }
    70% {
        clip-path: inset(38% 0 2% 0);
    }
    75% {
        clip-path: inset(75% 0 17% 0);
    }
    80% {
        clip-path: inset(14% 0 37% 0);
    }
    85% {
        clip-path: inset(12% 0 46% 0);
    }
    90% {
        clip-path: inset(52% 0 10% 0);
    }
    95% {
        clip-path: inset(58% 0 14% 0);
    }
    100% {
        clip-path: inset(45% 0 23% 0);
    }
  `

  const glitch2 = keyframes`
    0% {
        clip-path: inset(77% 0 1% 0);
    }
    5% {
        clip-path: inset(54% 0 40% 0);
    }
    10% {
        clip-path: inset(58% 0 12% 0);
    }
    15% {
        clip-path: inset(4% 0 49% 0);
    }
    20% {
        clip-path: inset(89% 0 7% 0);
    }
    25% {
        clip-path: inset(3% 0 54% 0);
    }
    30% {
        clip-path: inset(37% 0 60% 0);
    }
    35% {
        clip-path: inset(4% 0 9% 0);
    }
    40% {
        clip-path: inset(32% 0 50% 0);
    }
    45% {
        clip-path: inset(87% 0 5% 0);
    }
    50% {
        clip-path: inset(71% 0 24% 0);
    }
    55% {
        clip-path: inset(23% 0 1% 0);
    }
    60% {
        clip-path: inset(7% 0 49% 0);
    }
    65% {
        clip-path: inset(55% 0 29% 0);
    }
    70% {
        clip-path: inset(48% 0 33% 0);
    }
    75% {
        clip-path: inset(61% 0 19% 0);
    }
    80% {
        clip-path: inset(72% 0 3% 0);
    }
    85% {
        clip-path: inset(95% 0 2% 0);
    }
    90% {
        clip-path: inset(22% 0 56% 0);
    }
    95% {
        clip-path: inset(15% 0 6% 0);
    }
    100% {
        clip-path: inset(97% 0 1% 0);
    }
  `

  const blink = keyframes`
    0% {opacity: 0}
    49% {opacity: 0}
    50% {opacity: 1}
  `

  return (
    <Flex
      backgroundColor="dark"
      py={7}
      px={[7, 0]}
      pb={[7, 7]}
      justifyContent="center"
      alignItems="center"
      flexDirection={["column", "row"]}
      sx={{
        position: "relative",
      }}
    >
      {showRanking && <Ranking close={() => setShowRanking(false)} />}
      <Text
        fontFamily="PressStart"
        p={4}
        sx={{
          position: ["static", "absolute"],
          left: "50px",
          top: "72px",
          border: "2px solid transparent",
          textDecoration: "none",
          "&:hover": {
            border: "2px solid #ffffff",
          },
          cursor: "pointer",
        }}
        color="white"
        onClick={() => setShowRanking(true)}
      >
        rankings{" "}
        <Text
          as="span"
          sx={{
            animation: `${blink} 1s infinite`,
          }}
        >
          {">"}
        </Text>
      </Text>
      <Box id="play">
        <Link
          sx={{
            textDecoration: "none",
          }}
          href="/invaders"
        >
          <Text
            color="#0bffc9"
            fontSize={[3, "67px"]}
            data-text="SPINUP"
            sx={{
              textShadow: "2px 2px 20px #09ffc966",
              position: "relative",
              fontFamily: "BreakBeatBtnOutline",
              textTransform: "uppercase",
              lineHeight: 1,
              fontWeight: "bold",
              "&:before, &:after": {
                content: "attr(data-text)",
                position: "absolute",
                top: 0,
                left: 0,
                width: "100%",
                height: "100%",
              },
              "&:before": {
                top: 0,
                color: "#0bffc9",
                overflow: "hidden",
                left: "2px",
                textShadow: "-1px 0 red",
                background: "dark",
                animation: `${glitch1} 2s infinite linear alternate-reverse`,
              },
              "&:after": {
                top: 0,
                color: "#0bffc9",
                overflow: "hidden",
                left: "-2px",
                textShadow: "-1px 0 blue",
                background: "dark",
                animation: `${glitch2} 2s infinite linear alternate-reverse`,
              },
            }}
          >
            SPINUP
          </Text>

          <Text
            color="#0bffc9"
            fontSize={"50px"}
            data-text="INVADERS"
            sx={{
              textShadow: "2px 2px 20px #09ffc966",
              position: "relative",
              fontFamily: "BreakBeatBtnOutline",
              textTransform: "uppercase",
              lineHeight: 1,
              fontWeight: "bold",
              "&:before, &:after": {
                content: "attr(data-text)",
                position: "absolute",
                top: 0,
                left: 0,
                width: "100%",
                height: "100%",
              },
              "&:before": {
                top: 0,
                color: "#0bffc9",
                overflow: "hidden",
                left: "2px",
                textShadow: "-1px 0 red",
                background: "dark",
                animation: `${glitch1} 2s infinite linear alternate-reverse`,
              },
              "&:after": {
                top: 0,
                color: "#0bffc9",
                overflow: "hidden",
                left: "-2px",
                textShadow: "-1px 0 blue",
                background: "dark",
                animation: `${glitch2} 2s infinite linear alternate-reverse`,
              },
            }}
          >
            INVADERS
          </Text>
        </Link>
      </Box>

      <Link
        sx={{
          textDecoration: "none",
        }}
        href="/invaders"
      >
        <Text
          fontFamily="PressStart"
          padding={4}
          sx={{
            position: ["static", "absolute"],
            right: "50px",
            top: "72px",
            border: "2px solid transparent",
            textDecoration: "none",
            "&:hover": {
              border: "2px solid #ffffff",
            },
          }}
          color="white"
        >
          insert coin{" "}
          <Text
            as="span"
            sx={{
              animation: `${blink} 1s infinite`,
            }}
          >
            {">"}
          </Text>
        </Text>
      </Link>
    </Flex>
  )
}

export default GameBanner
