import React from "react"
import PropTypes from "prop-types"
import { Box } from "rebass"
import Header from "./header"
import Footer from "./footer"
import { Helmet } from "react-helmet"
import "../../sass/index.scss"

const Layout = ({ forceOpaqueHeader, children }) => {
  return (
    <>
      <Helmet>
        <link
          rel="preload"
          href="https://spinup-static-website.s3.eu-south-1.amazonaws.com/fonts/EuclidCircularA-Light.ttf"
          as="font"
          type="font/ttf"
          crossorigin
        />
        <link
          rel="preload"
          href="https://spinup-static-website.s3.eu-south-1.amazonaws.com/fonts/EuclidCircularA-Regular.ttf"
          as="font"
          type="font/ttf"
          crossorigin
        />
        <link
          rel="preload"
          href="https://spinup-static-website.s3.eu-south-1.amazonaws.com/fonts/EuclidCircularA-Medium.ttf"
          as="font"
          type="font/ttf"
          crossorigin
        />
        <link
          rel="preload"
          href="https://spinup-static-website.s3.eu-south-1.amazonaws.com/fonts/EuclidCircularA-SemiBold.ttf"
          as="font"
          type="font/ttf"
          crossorigin
        />
        <link
          rel="preload"
          href="https://spinup-static-website.s3.eu-south-1.amazonaws.com/fonts/EuclidCircularA-Bold.ttf"
          as="font"
          type="font/ttf"
          crossorigin
        />
      </Helmet>
      <Header forceOpaque={forceOpaqueHeader} />
      <Box>
        <main>{children}</main>
      </Box>
      <Footer />
    </>
  )
}

Layout.propTypes = {
  children: PropTypes.node.isRequired,
}

export default Layout
